import React from "react"
import styled from "styled-components"
import GrayLinesBg from "../images/gray_lines_.svg"

const StyledGrayLines = styled.div`
   background-image: url(${GrayLinesBg});
   background-repeat: no-repeat;
   height: 250px;
   background-size: cover;
   background-position: center center;
   @media(min-width: 807px) {
    height: 300px;
   } 
   @media(min-width: 1256px) {
    height: 536px;
    margin-top: -170px;
   }
`;

const GrayLines = () => (
  <StyledGrayLines/>
)

export default GrayLines