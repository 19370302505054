import React from "react"
import styled from "styled-components"
import scrollTo from "gatsby-plugin-smoothscroll"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebookF, faLinkedinIn, faTwitter } from "@fortawesome/free-brands-svg-icons"
import ContactImg from "../images/contact_img.png"
import FooterBigBg from "../images/contact_bg.png"
import FooterTopImage from "../images/footer_top_img.png"
import ContactBigImg from "../images/contact_big_img.png"
import Logo from "../images/footer_logo.png"
import FooterBottomImg from "../images/footer_bottom_img.svg"
import BgFooterFirstElipse from "../images/footer_first_elipse_bg.png"
import BgFooterSecElipse from "../images/footer_sec_elipse_bg.png"
import { Translate } from "gatsby-plugin-translate"
import { Link } from "gatsby"

const StyledMainContactWrapper = styled.div`
  position: relative;
  padding: 0 30px;
  padding-top: 50px;
  overflow: hidden;
  @media (min-width: 1366px) {
    padding-top: 166px;
  }
  @media (min-width: 1866px) {
    padding-top: 70px;
  }
`

const StyledTitle = styled.div`
  color: #FFFFFF;
  font-size: 26px;
  display: flex;
  justify-content: center;
  position: relative;
  padding-bottom: 107px;
  margin-top: 110px;

  h2 {
    font-weight: 800;
  }

  &:before {
    content: "";
    height: 10px;
    width: 10px;
    background-color: #FFFFFF;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: -10%;
  }

  @media (min-width: 1366px) {
    h2 {
      font-size: 44px;
    }
  }
`


const ContactBg = styled.div`
  @media (min-width: 320px) {
    background-image: url(${FooterBigBg});
    background-repeat: no-repeat;
    z-index: -2;
    position: absolute;
    top: -74px;
    left: 0;
    background-position: left -1400px top 10px;
    background-size: 3000px;
    height: 1100px;
    width: 2903px;
  }
  @media (min-width: 768px) {
    background-image: url(${FooterBigBg});
    background-position: left -800px top 9px;
    background-size: 2500px;
    height: 1100px;
    width: 2003px;
  }

  @media (min-width: 920px) {
    background-position: left -500px top -25px;
    background-size: 2500px;
    height: 5000px;
    width: 2000px;
  }

  @media (min-width: 1366px) {
    background-image: url(${FooterBigBg});
    background-position: left -208px top -35px;
    background-size: 2800px;
    width: 2803px;
  }
  @media (min-width: 1866px) {
    background-position: left -208px top -29px;
    background-size: 2750px;
    width: 2803px;
  }
`

const ContactTopImg = styled.div`
  @media (min-width: 768px) {
    position: absolute;
    background-image: url(${FooterTopImage});
    background-repeat: no-repeat;
    top: 83px;
    left: 18%;
    height: 128px;
    width: 140px;
    background-size: 123px;
  }
  @media (min-width: 1024px) {
    top: 85px;
    left: calc(52vw - 32%)
  }
  @media (min-width: 1366px) {
    top: 178px;
    left: calc(52vw - 26%);
  }
  @media (min-width: 1650px) {
    left: calc(52vw - 22%);
    top: 150px;
  }
  @media (min-width: 1866px) {
    top: 93px;
  }
`

const StyledContactBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 320px) {
    flex-direction: column;
  }
  @media (min-width: 768px) {
    flex-direction: row;
    max-width: 700px;
    margin: 0 auto;
  }
  @media (min-width: 1366px) {
    max-width: 1024px;
  }
`

const StyledContactImg = styled.div`
  width: 30%;
  height: 160px;
  background-image: url(${ContactImg});
  background-size: contain;
  background-repeat: no-repeat;
  @media (min-width: 320px) {
    width: 100%;
    background-position: center;
    margin-bottom: 61px;
  }
  @media (min-width: 768px) {
    margin-bottom: 127px;
  }
  @media (min-width: 1366px) {
    background-image: url(${ContactBigImg});
    height: 200px;
    background-size: contain;
  }
`

const StyledContactText = styled.div`
  width: 30%;
  line-height: 60px;

  p {
    font-size: 13px;
    font-weight: bold;
    color: #07064E;
  }

  h2 {
    font-size: 32px;
    font-weight: 800;
  }

  h2:nth-of-type(1) {
    color: #07064E;
  }

  h2:nth-of-type(2) {
    color: #FFFFFF !important;

    a {
      color: #FFFFFF !important;
    }
  }

  @media (min-width: 320px) {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 140px;
  }
  @media (min-width: 1366px) {
    p {
      font-size: 18px;
    }

    h2 {
      font-size: 44px;
    }
  }
`


const StyledMainFooterWrapper = styled.div`
  position: relative;
  overflow: hidden;
  @media (max-width: 768px) {
    margin-top: -85px;
  }
  @media (min-width: 768px) {
    padding: 0 60px;
  }
  @media (min-width: 1500px) {
    padding: 0 156px;
  }
`

const StyledFooter = styled.div`
  padding-top: 70px;
  padding-bottom: 120px;
  @media (min-width: 768px) {
    max-width: 700px;
    margin: 0 auto;
  }
  @media (min-width: 1366px) {
    max-width: 1200px;
    display: flex;
  }
`

const StyledLogoImg = styled.div`
  background-image: url(${Logo});
  background-repeat: no-repeat;
  height: 35px;
  width: 200px;
  margin-bottom: 30px;
  cursor: pointer;
`

const StyledTextFooter = styled.div`
  color: #07064E;
  @media (min-width: 320px) {
    span {
      display: block;
    }
  }
  @media (min-width: 768px) {
    display: flex;
    font-size: 12px;
  }
`

const FooterBottomImage = styled.div`
  background-image: url(${FooterBottomImg});
  position: absolute;
  bottom: -222px;
  left: 0;
  height: 40%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
}

@media (min-width: 768px) {
  bottom: -162px;
  height: 51%;
}

@media (min-width: 1366px) {
  height: 74%;
  bottom: -166px;
`


const FirstBox = styled.div`
  height: 80px;

  span, p {
    cursor: pointer;
  }

  @media (min-width: 768px) {
    height: auto;
    margin-right: 18px;
  }
`

const SecBox = styled.div`
  margin-bottom: 21px;

  span, p {
    cursor: pointer;
  }
`

const ThirdBox = styled.div`
  margin-bottom: 5px;

  span, p {
    cursor: pointer;
  }

  @media (min-width: 1366px) {
    margin-right: 30px;
  }
`

const FourthBox = styled.div`
  height: 51px;
  margin-bottom: 8px;

  span, p {
    cursor: pointer;
  }
`

const FifthBox = styled.div`
  span, p {
    cursor: pointer;
  }

  @media (min-width: 1366px) {
    margin-right: 51px;
  }
`

const StyledSocialMedia = styled.div`
  display: flex;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 120px;
  @media (min-width: 768px) {
    padding: 0;
  }
`

const StyledCircleBox = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: #2BF5BA;
  color: #ffffff;
  font-size: 15px;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    color: #ffffff !important;
    cursor: pointer;
  }

  @media (min-width: 768px) {
    display: flex;
  }
  @media (min-width: 1366px) {
    background-color: #ffffff;
    a {
      color: #2BF5BA !important;
    }
  }
`

const FirstFooterWrapper = styled.div`
  position: relative;
  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 40%;
  }
  @media (min-width: 1366px) {
    flex-direction: row;
    margin-right: 30px;
    width: auto;
  }
`

const BgFooterElipse = styled.div`
  background-image: url(${BgFooterFirstElipse});
  background-repeat: no-repeat;
  background-size: 92px;
  position: absolute;
  top: -35px;
  left: 206px;
  height: 116px;
  width: 115px;
  @media (min-width: 768px) {
    background-size: 63px;
    top: -28px;
    left: 169px;
  }
`

const BgSecFooterElipse = styled.div`
  background-image: url(${BgFooterSecElipse});
  background-repeat: no-repeat;
  background-size: 92px;
  position: absolute;
  top: 226px;
  left: 108px;
  height: 91px;
  width: 70px;
  @media (min-width: 320px) {
    width: 90px;
    top: -83px;
    left: 72px;
  }
  @media (min-width: 768px) {
    background-size: 57px;
    top: 34px;
    left: 104px;
    width: 70px;
  }
}
`

const SecFooterWrapper = styled.div`
  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 20%;
  }
  @media (min-width: 1366px) {
    flex-direction: row;
    margin-right: 30px;
    width: auto;
  }
`

const ThirdFooterWrapper = styled.div`
  position: relative;
  @media (min-width: 768px) {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 40%;
  }
  @media (min-width: 1366px) {
    flex-direction: row;
    width: auto;
  }
`


const Contact = () => (
  <>
    <StyledMainContactWrapper id={"contact"}>
      <ContactBg/>
      <ContactTopImg/>
      <StyledTitle>
        <h2><Translate id="menu.contact"/></h2>
      </StyledTitle>
      <StyledContactBox>
        <StyledContactImg/>
        <StyledContactText>
          <p><Translate id="contact.call_us"/></p>
          <h2>biuro@wyborek.pl</h2>
          <h2>+48 535 150 221</h2>
        </StyledContactText>
      </StyledContactBox>
      <StyledMainFooterWrapper>
        <StyledFooter>
          <StyledLogoImg onClick={() => scrollTo("#slider")}/>
          <StyledTextFooter>
            <FirstFooterWrapper>
              <BgFooterElipse/>
              <FirstBox>
                <span>Bolesława Limanowskiego 36/30</span>
                31-551 Kraków
              </FirstBox>
              <SecBox>
                <span>+48 535 150 221</span>
                biuro@wyborek.pl
              </SecBox>
            </FirstFooterWrapper>
            <SecFooterWrapper>
              <ThirdBox>
                <span onClick={() => scrollTo("#aboutSystem")}><Translate id="menu.about_system"/></span>
                <p onClick={() => scrollTo("#forWhom")}><Translate id="menu.for_whom"/></p>
              </ThirdBox>
              <FourthBox>
                <span onClick={() => scrollTo("#security")}><Translate id="menu.security"/></span>
                {/*<p onClick={() => scrollTo("#pricing")}><Translate id="menu.price_plan"/></p>*/}
                <Link to="/regulamin">Regulamin</Link>
              </FourthBox>
            </SecFooterWrapper>
            <ThirdFooterWrapper>
              <BgSecFooterElipse/>
              <FifthBox>
                <span onClick={() => scrollTo("#technology")}><Translate id="menu.technologies"/></span>
                {/*<p onClick={() => scrollTo("#customers")}><Translate id="menu.customers"/></p>*/}
              </FifthBox>
              <StyledSocialMedia>
                <StyledCircleBox>
                  <a href="https://www.facebook.com/wyborek" target="_blank">
                    <FontAwesomeIcon icon={faFacebookF}/>
                  </a>
                </StyledCircleBox>
                <StyledCircleBox>
                  <a href="https://www.linkedin.com/company/wyborek" target="_blank">
                    <FontAwesomeIcon icon={faLinkedinIn}/>
                  </a>
                </StyledCircleBox>
                <StyledCircleBox>
                  <a href="https://twitter.com/wyborek" target="_blank">
                    <FontAwesomeIcon icon={faTwitter}/>
                  </a>
                </StyledCircleBox>
              </StyledSocialMedia>
            </ThirdFooterWrapper>
          </StyledTextFooter>
        </StyledFooter>
        <FooterBottomImage/>
      </StyledMainFooterWrapper>
    </StyledMainContactWrapper>
  </>
)

export default Contact
