import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AboutSystem from "../components/aboutSystem"
import SliderComponent from "../components/slider"
import styled from "styled-components"
import MockupBgSmall from "../images/mockup_bg_small.svg"
import MockupBgMiddle from "../images/mockup_bg_middle.svg"
import MockupBgBig from "../images/mockup_bg_big.svg"
import ForWhom from "../components/forWhom"
// import Customers from "../components/customers"
import AboutUsBg from "../images/about_us_bg.png"
import AboutUsBigBg from "../images/about_system_bg.svg"
import HeaderBgBig from "../images/header_bg_big.svg"
import GrayLines from "../components/grayLines"
import Pricing from "../components/pricing"
import Contact from "../components/contact"
import "../components/fontawsomeIcon"
import Technologies from "../components/technologies"
import Security from "../components/security"

const StyledHidden = styled.div`
  overflow: hidden;
`

const StyledMockupBgWrapper = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${MockupBgSmall});
  background-repeat: no-repeat;
  background-position: right -230px top -230px;
  background-size: 600px;
  display: block;
  @media (min-width: 320px ) {
    background-image: url(${MockupBgMiddle});
    background-position: right -678px top 118px;
    background-size: 972px;
  }
  @media (min-width: 417px ) {
    background-size: 1011px;
  }
  @media (min-width: 465px ) {
    background-size: 1026px;
    background-position: right -678px top 90px;
  }
  @media (min-width: 480px ) {
    background-size: 1058px;
  }
  @media (min-width: 571px ) {
    background-size: 1056px;
    background-position: right -581px top 122px;
  }
  @media (min-width: 768px ) {
    background-image: url(${MockupBgMiddle});
    background-position: right -887px top 122px;
    background-size: 1472px;
  }
  @media (min-width: 1024px ) {
    background-image: url(${MockupBgMiddle});
    background-position: right -871px top -8px;
    background-size: 1460px;
  }
  @media (min-width: 1366px ) {
    background-image: url(${MockupBgBig});
    background-position: right -76px top 42px;
    transform: rotate(-44deg);
    background-size: 1371px;
    height: 1276px;
    width: 1994px;
  }
  @media (min-width: 1450px ) {
    background-size: 1318px;
    height: 1389px;
    width: 1984px;
    background-position: right -171px top 162px;
    transform: rotate(-53deg);
  }
  @media (min-width: 1550px) {
    background-size: 1365px;
    height: 1933px;
    width: 2366px;
    background-position: right -143px top 143px;
    transform: rotate(-49deg);
  }
  @media (min-width: 1736px) {
    background-image: url(${HeaderBgBig});
    background-size: 2075px;
    height: 1061px;
    width: 4939px;
    background-position: right 732px top 88px;
    transform: rotate(185deg);
  }
  @media (min-width: 2000px) {
    background-position: right 917px top 10px;
  }
  @media (min-width: 2370px) {
    background-position: right 1055px top 10px;
  }
`

const StyledWrapper = styled.div`
  position: relative;
  @media (max-width: 570px) {
    height: 100vh;
  }
  @media (min-width: 571px) {
    height: 108vh;
  }
  @media (min-width: 768px) {
    height: 138vh;
  }
  @media (min-width: 1024px) {
    height: 122vh;
    overflow: hidden;
  }
  @media (min-width: 1366px) {
    height: 104vh;
  }
`

const StyledAboutUs = styled.div`
  position: relative;
`

const StyledAboutUsBg = styled.div`
  position: absolute;
  z-index: -1;
  top: -150px;
  left: 0;
  width: 100%;
  height: 120%;
  background-image: url(${AboutUsBg});
  background-repeat: no-repeat;
  display: block;
  background-position: top;
  @media (min-width: 1600px) {
    background-image: url(${AboutUsBigBg});
  }
`

const IndexPage = () => {
  return (
    <Layout>
      <StyledHidden>
        <SEO title="Home"/>
        <StyledWrapper>
          <StyledMockupBgWrapper/>
          <SliderComponent/>
        </StyledWrapper>
        <StyledAboutUs>
          <StyledAboutUsBg/>
          <AboutSystem/>
        </StyledAboutUs>
        <GrayLines/>
        <ForWhom/>
        <Security/>
        <Technologies/>
        {/*<Customers/>*/}
        <Pricing/>
        <Contact/>
      </StyledHidden>
    </Layout>
  )
}

export default IndexPage
