import React from "react"
import styled from "styled-components"

import AgendaImage from "../images/agenda.svg"
import FormImage from "../images/form.svg"
import ChatImage from "../images/chat.svg"
import VoteImage from "../images/vote.svg"
import ReportImage from "../images/report.svg"
import NotificationImage from "../images/notification.svg"
import QuestionImage from "../images/question.svg"
import VisibilityImage from "../images/visibility.svg"
import AutomaticImage from "../images/automatic.svg"
import ProxyImage from "../images/proxy.svg"
import StyledMockupAgenda from "../images/about_system_agenda.png"
import StyledMockupForm from "../images/about_system_ankieta.png"
import StyledMockupQuestion from "../images/about_system_tak_nie_czas.png"
import StyledMockupAutomatic from "../images/about_system_automatic.png"
import StyledMockupVisibility from "../images/about_system_visibility.png"
import StyledMockupVote from "../images/about_system_vote.png"
import StyledMockupChat from "../images/about_system_chat.png"
import StyledMockupReport from "../images/about_system_raport.png"
import StyledMockupNotification from "../images/about_system_notification.png"
import StyledMockupProxy from "../images/proxy.png"


import { Translate } from "gatsby-plugin-translate"

const StyledMainWrapper = styled.div`
  position: relative;
  padding: 0 30px;
  margin: 0 auto;
  @media (min-width: 768px) {
    max-width: calc(100vw - 57px);
    padding: 0;
  }
  @media (min-width: 1024px) {
    max-width: calc(100vw - 129px);
    margin-top: -200px;
  }
  @media (min-width: 1258px) {
    //max-width: 1124px;
    max-width: 1088px;
  }
  @media (min-width: 1366px) {
    margin-top: 0;
  }
`

const StyledAboutSystemBgWrapper = styled.div`
  @media (min-width: 1024px) {
    padding-top: 130px;
  }
`

const StyledTitle = styled.div`
  color: #07064E;
  font-size: 26px;
  display: flex;
  justify-content: center;
  position: relative;
  padding-bottom: 107px;
  padding-top: 120px;

  h2 {
    font-weight: 800;
  }

  &:before {
    content: "";
    height: 10px;
    width: 10px;
    background-color: #2BF5BA;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 39%;
  }

  @media (min-width: 1024px) {
    padding-top: 0;
    &:before {
      top: -4%;
    }
  }
  @media (min-width: 1366px) {
    h2 {
      font-size: 44px;
    }
  }
`

const StyledAboutBox = styled.div`
  color: #07064E;
  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    &:nth-of-type(odd) {
      flex-direction: row-reverse;
    }
  }
  @media (min-width: 1182px) {
    display: flex;
    justify-content: space-around;
  }
`

const StyledAboutUsWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 23px;
  padding-top: 50px;
`

const StyledTextWidth = styled.div`
  margin-right: 50px;
  @media (min-width: 768px) {
    width: 50%;
  }
  @media (min-width: 1024px) {
    width: 56%;
  }
`

const StyledImgWidth = styled.div`
  @media (min-width: 768px) {
    width: 50%;
  }
`

const AgendaImg = styled.div`
  background-image: url(${AgendaImage});
  height: 70px;
  width: 104px;
  background-repeat: no-repeat;
  margin-right: 21px;
`

const FormImg = styled.div`
  background-image: url(${FormImage});
  height: 70px;
  width: 104px;
  background-repeat: no-repeat;
  margin-right: 21px;
`

const ChatImg = styled.div`
  background-image: url(${ChatImage});
  height: 70px;
  width: 104px;
  background-repeat: no-repeat;
  margin-right: 21px;
`
const VoteImg = styled.div`
  background-image: url(${VoteImage});
  height: 70px;
  width: 104px;
  background-repeat: no-repeat;
  margin-right: 21px;
`

const ReportImg = styled.div`
  background-image: url(${ReportImage});
  height: 70px;
  width: 104px;
  background-repeat: no-repeat;
  margin-right: 21px;
`

const NotificationImg = styled.div`
  background-image: url(${NotificationImage});
  height: 70px;
  width: 104px;
  background-repeat: no-repeat;
  margin-right: 21px;
`
const QuestionImg = styled.div`
  background-image: url(${QuestionImage});
  height: 70px;
  width: 104px;
  background-repeat: no-repeat;
  margin-right: 21px;
`
const VisibilityImg = styled.div`
  background-image: url(${VisibilityImage});
  height: 70px;
  width: 104px;
  background-repeat: no-repeat;
  margin-right: 21px;
`
const AutomaticImg = styled.div`
  background-image: url(${AutomaticImage});
  height: 70px;
  width: 104px;
  background-repeat: no-repeat;
  margin-right: 21px;
`
const ProxyImg = styled.div`
  background-image: url(${ProxyImage});
  height: 70px;
  width: 104px;
  background-repeat: no-repeat;
  margin-right: 21px;
`

const StyledHeaderText = styled.div`
  font-size: 20px;
  font-weight: 800;
  line-height: 40px;

  span {
    display: block;
  }

  @media (min-width: 768px) {
    font-size: 28px;
    line-height: 40px;
  }
  @media (min-width: 1024px) {
    span {
      display: inline;
    }

    margin-top: 10px;
  }
`

const StyledTextDescription = styled.div`
  min-height: 125px;
  line-height: 31px;
  @media (max-width: 767px) {
    padding-bottom: 25px;
  }
  @media (min-width: 1366px) {
    p {
      &:nth-of-type(1) {
        padding-left: 119px;
      }

      &:nth-of-type(2) {
        padding-right: 93px;
      }

      &:nth-of-type(3) {
        padding-right: 136px;
      }

      &:nth-of-type(4) {
        padding-right: 87px;
      }
    }
  }
`
const StyledMockupAgendaImage = styled.div`
  background-image: url(${StyledMockupAgenda});
  width: 80%;
  height: 371px;
  display: block;
  background-repeat: no-repeat;
  background-position: right;
  @media (max-width: 767px) {
    background-position: center center;
    background-size: 310px;
  }
  @media (min-width: 768px) {
    background-position: bottom;
    background-size: 94%;
  }
`
const StyledMockupFormImage = styled.div`
  background-image: url(${StyledMockupForm});
  width: 80%;
  height: 371px;
  display: block;
  background-repeat: no-repeat;
  background-position: right;
  @media (max-width: 767px) {
    background-position: center center;
    background-size: 310px;
  }
  @media (min-width: 768px) {
    background-position: bottom;
    background-size: 90%;
  }
`
const StyledMockupQuestionImage = styled.div`
  background-image: url(${StyledMockupQuestion});
  width: 100%;
  height: 520px;
  display: block;
  background-repeat: no-repeat;
  background-position: right;
  @media (max-width: 767px) {
    background-position: center center;
    background-size: 310px;
  }
  @media (min-width: 768px) {
    background-position: bottom;
    background-size: 94%;
  }
`
const StyledMockupVoteImage = styled.div`
  background-image: url(${StyledMockupVote});
  width: 80%;
  height: 371px;
  display: block;
  background-repeat: no-repeat;
  background-position: right;
  @media (max-width: 767px) {
    background-position: center center;
    background-size: 310px;
  }
  @media (min-width: 768px) {
    background-position: bottom;
    background-size: 94%;
  }
`
const StyledMockupAutomaticImage = styled.div`
  background-image: url(${StyledMockupAutomatic});
  width: 80%;
  height: 371px;
  display: block;
  background-repeat: no-repeat;
  background-position: right;
  @media (max-width: 767px) {
    background-position: center center;
    background-size: 310px;
  }
  @media (min-width: 768px) {
    background-position: bottom;
    background-size: 94%;
  }
`
const StyledMockupVisibilityImage = styled.div`
  background-image: url(${StyledMockupVisibility});
  width: 80%;
  height: 371px;
  display: block;
  background-repeat: no-repeat;
  background-position: right;
  @media (max-width: 767px) {
    background-position: center center;
    background-size: 310px;
  }
  @media (min-width: 768px) {
    background-position: bottom;
    background-size: 94%;
  }
`
const StyledMockupChatImage = styled.div`
  background-image: url(${StyledMockupChat});
  width: 80%;;
  height: 371px;
  display: block;
  background-repeat: no-repeat;
  background-position: right;
  @media (max-width: 767px) {
    background-position: center center;
    background-size: 310px;
  }
  @media (min-width: 768px) {
    background-position: bottom;
    background-size: 94%;
  }
`
const StyledMockupReportImage = styled.div`
  background-image: url(${StyledMockupReport});
  width: 80%;;
  height: 371px;
  display: block;
  background-repeat: no-repeat;
  background-position: right;
  @media (max-width: 767px) {
    background-position: center center;
    background-size: 310px;
  }
  @media (min-width: 768px) {
    background-position: bottom;
    background-size: 94%;
  }
`
const StyledMockupNotificationImage = styled.div`
  background-image: url(${StyledMockupNotification});
  width: 100%;
  height: 371px;
  display: block;
  background-repeat: no-repeat;
  background-position: center;
  @media (max-width: 767px) {
    background-position: center;
    background-size: 310px;
  }
  @media (min-width: 768px) {
    background-position: center;
    background-size: 94%;
  }
`
const StyledMockupProxyImage = styled.div`
  background-image: url(${StyledMockupProxy});
  width: 100%;
  height: 371px;
  display: block;
  background-repeat: no-repeat;
  background-position: center;
  @media (max-width: 767px) {
    background-position: center;
    background-size: 310px;
  }
  @media (min-width: 768px) {
    background-position: center;
    background-size: 94%;
  }
`


const AboutSystem = () => (
  <StyledMainWrapper id={"aboutSystem"}>
    <StyledAboutSystemBgWrapper/>
    <StyledTitle>
      <h2><Translate id="about_system"/></h2>
    </StyledTitle>

    {/* AGENDA! */}
    <StyledAboutBox>
      <StyledTextWidth>
        <StyledAboutUsWrapper>
          <AgendaImg/>
          <StyledHeaderText>
            <h2>
              Twórz agendę<br></br> wydarzenia
            </h2>
          </StyledHeaderText>
        </StyledAboutUsWrapper>
        <StyledTextDescription>
          <p>
            Agenda pozwala Ci podzielić wydarzenie na konkretne punkty
            i w ramach tych punktów opisywać kolejne etapy wydarzenia. </p>
        </StyledTextDescription>
      </StyledTextWidth>
      <StyledImgWidth>
        <StyledMockupAgendaImage/>
      </StyledImgWidth>
    </StyledAboutBox>

    {/*ANKIETUJ*/}
    <StyledAboutBox>
      <StyledTextWidth>
        <StyledAboutUsWrapper>
          <FormImg/>
          <StyledHeaderText>
            <h2>
              Głosowanie typu<br></br> “Ankieta”
            </h2>
            {/*<Translate id="about_system.poll"/> */}
          </StyledHeaderText>
        </StyledAboutUsWrapper>
        <StyledTextDescription>
          <p>
            Głosowanie typu „Ankieta” to doskonałe głosowanie
            jeśli chcesz dodać więcej niż 3 odpowiedzi oraz dowolnie edytować ich treść.
            {/*<Translate id="about_system.poll.description"/>*/}
          </p>
        </StyledTextDescription>
      </StyledTextWidth>
      <StyledImgWidth>
        <StyledMockupFormImage/>
      </StyledImgWidth>
    </StyledAboutBox>

    {/*TAK czy nie*/}
    <StyledAboutBox>
      <StyledTextWidth>
        <StyledAboutUsWrapper>
          <QuestionImg/>
          <StyledHeaderText>
            <h2>
              Głosowanie typu<br></br> Tak-Nie
            </h2>
            {/*<Translate id="about_system.poll"/> */}
          </StyledHeaderText>
        </StyledAboutUsWrapper>
        <StyledTextDescription>
          <p>
            Głosowanie typu „TAK-NIE” to głosowanie, które posiada w odpowiedziach 3 możliwości: tak, nie, wstrzymuję
            się (ich treść można zmienić).
            {/*<Translate id="about_system.poll.description"/>*/}
          </p>
        </StyledTextDescription>
      </StyledTextWidth>
      <StyledImgWidth>
        <StyledMockupQuestionImage/>
      </StyledImgWidth>
    </StyledAboutBox>

    {/*  GŁOSOWANIE PUNKTOWE */}
    <StyledAboutBox>
      <StyledTextWidth>
        <StyledAboutUsWrapper>
          <VoteImg/>
          <StyledHeaderText>
            <h2>
              Głosowanie<br></br> punktowe
              {/*<Translate id="about_system.vote"/>*/}
            </h2>
            {/*<Translate id="about_system.vote_for"/>*/}
          </StyledHeaderText>
        </StyledAboutUsWrapper>
        <StyledTextDescription>
          <p>
            Głosowanie punktowe pozwala odpowiadać za pomocą
            dowolnej skali punktowej w ustalonym zakresie.
            {/*<Translate id="about_system.vote_for.description"/>*/}
          </p>
        </StyledTextDescription>
      </StyledTextWidth>
      <StyledImgWidth>
        <StyledMockupVoteImage/>
      </StyledImgWidth>
    </StyledAboutBox>


    {/*AUTOMATYCZNIE ALBO RĘCZNIE*/}
    <StyledAboutBox>
      <StyledTextWidth>
        <StyledAboutUsWrapper>
          <AutomaticImg/>
          <StyledHeaderText>
            <h2>
              Automatyczne<br></br> albo ręczne

              {/*<Translate id="about_system.automatically"/>*/}
            </h2>
            {/*<Translate id="about_system.or_manually"/>*/}

          </StyledHeaderText>
        </StyledAboutUsWrapper>
        <StyledTextDescription>
          <p>
            Funkcja automatycznego startu głosowania umożliwia wcześniejsze
            zaplanowanie kiedy uczestnicy będą mieli możliwość oddania głosów.
            Ustawienie ręczne sprawdzi się gdy czas nie ma dla organizatora znaczenia.
            {/*<Translate id="about_system.automatically_or_manually.description"/>*/}
          </p>
        </StyledTextDescription>
      </StyledTextWidth>
      <StyledImgWidth>
        <StyledMockupAutomaticImage/>
      </StyledImgWidth>
    </StyledAboutBox>

    {/*TAJNIE JAWNE*/}
    <StyledAboutBox>
      <StyledTextWidth>
        <StyledAboutUsWrapper>
          <VisibilityImg/>
          <StyledHeaderText>
            <h2>
              Tajne lub<br></br> jawne
              {/*<Translate id="about_system.secretly"/>*/}
            </h2>
            {/*<Translate id="about_system.or_openly"/>*/}
          </StyledHeaderText>
        </StyledAboutUsWrapper>
        <StyledTextDescription>
          <p>
            System daje możliwość organizacji zarówno tajnych jak i jawnych głosowań.
            Tajne to takie, w którym podmiot organizujący widzi jedynie ogólne wyniki ankiety,
            bez możliwości podejrzenia kto jaki głos oddał. Jawne nie mają żadnych ograniczeń.
            {/*<Translate id="about_system.secretly_or_openly.description"/>*/}
          </p>
        </StyledTextDescription>
      </StyledTextWidth>
      <StyledImgWidth>
        <StyledMockupVisibilityImage/>
      </StyledImgWidth>
    </StyledAboutBox>


    {/*WIDEOKONFERENCJA*/}
    <StyledAboutBox>
      <StyledTextWidth>
        <StyledAboutUsWrapper>
          <ChatImg/>
          <StyledHeaderText><h2>
            {/*<Translate id="about_system.videoconference"/>*/}
            Czat<br></br> i wideokonferencja
          </h2>
            {/*<Translate id="about_system.and_chat"/>*/}
          </StyledHeaderText>
        </StyledAboutUsWrapper>
        <StyledTextDescription>
          <p>
            Tworząc wydarzenie możesz uruchomić usługę wideokonferencji i czatu.
            Są to dodatkowo płatne funkcje.
            {/*<Translate id="about_system.videoconference.description"/>*/}
          </p>
        </StyledTextDescription>
      </StyledTextWidth>
      <StyledImgWidth>
        <StyledMockupChatImage/>
      </StyledImgWidth>
    </StyledAboutBox>

    {/*SYSTEM RAPORTOWY*/}
    <StyledAboutBox>
      <StyledTextWidth>
        <StyledAboutUsWrapper>
          <ReportImg/>
          <StyledHeaderText><h2>
            System <br></br> Raportowy
          </h2>
            {/*<Translate id="about_system.reporting_system"/>*/}

          </StyledHeaderText>
        </StyledAboutUsWrapper>
        <StyledTextDescription>
          <p>

            {/*<Translate id="about_system.reporting_system.description"/>*/}
            Po zakończonym wydarzeniu możesz sprawdzić wyniki online lub wygenerować raport
            w formacie PDF
          </p>
        </StyledTextDescription>
      </StyledTextWidth>
      <StyledImgWidth>
        <StyledMockupReportImage/>
      </StyledImgWidth>
    </StyledAboutBox>

    {/*POWIADOMIENIA*/}
    <StyledAboutBox>
      <StyledTextWidth>
        <StyledAboutUsWrapper>
          <NotificationImg/>
          <StyledHeaderText><h2>
            Dostęp przez SMSy, Kody,
            Email lub Publiczne
          </h2>
            {/*<Translate id="about_system.reporting_system"/>*/}

          </StyledHeaderText>
        </StyledAboutUsWrapper>
        <StyledTextDescription>
          <p>

            {/*<Translate id="about_system.reporting_system.description"/>*/}
            Uczestnicy mogą autoryzować się w aplikacji za pomocą mejla lub Smsa,
            lub wygenerowanego wcześniej kodu lub możesz stworzyć wydarzenie
            otwarte dla wszystkich
          </p>
        </StyledTextDescription>
      </StyledTextWidth>
      <StyledImgWidth>
        <StyledMockupNotificationImage/>
      </StyledImgWidth>
    </StyledAboutBox>

    {/* PEŁNOMOCNICTWA */}
    <StyledAboutBox>
      <StyledTextWidth>
        <StyledAboutUsWrapper>
          <ProxyImg/>
          <StyledHeaderText><h2>
            Głosuj jako
            <br></br>
            pełnomocnik
          </h2>
            {/*<Translate id="about_system.reporting_system"/>*/}

          </StyledHeaderText>
        </StyledAboutUsWrapper>
        <StyledTextDescription>
          <p>

            {/*<Translate id="about_system.reporting_system.description"/>*/}
            W aplikacji możesz uczestnikowi ustawić rolę pełnomocnika. Dzięki temu może zagłosować w imieniu dowolnej
            liczby osób.
          </p>
        </StyledTextDescription>
      </StyledTextWidth>
      <StyledImgWidth>
        <StyledMockupProxyImage/>
      </StyledImgWidth>
    </StyledAboutBox>

  </StyledMainWrapper>
)

export default AboutSystem
