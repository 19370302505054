import { FunctionComponent, useState } from "react"
// @ts-ignore
import React from "react";
import styled from "styled-components"
import CustomButton from "../common/customButton"
import { navigate } from "gatsby"

interface TestProps {
  activeSection:string;
}

const TableWrapper = styled.div`
  position: relative;
  th, td {
    border-right: 2px solid lightslategray;
    text-align: center;
    height: 50px;
    color: #FFF;
    vertical-align: middle;
  }
  @media(max-width: 1000px) {
    width: fit-content;
    margin: 0 auto;
    th, td {
      border-right: none;
    }
  }
`;

const TableWhiteRow = styled.tr`
  th {
    min-width: 170px;
    color: #2AF5BA;
    font-size: 2.5rem;
    span {
      font-size: 15px;
      color: grey;
    }
  }
  &.grey {
    td {
      color: grey;
    }
  }
  
  @media(max-width: 1000px) {
    td,th {
      display: none;
    }
    td:nth-of-type(${({ priceIndex }) => priceIndex}),
    th:nth-of-type(${({ priceIndex }) => priceIndex -1}){
      display: flex !important;
      justify-content: flex-end;
      align-items: center;
    }
  }
`;
const SideCell = styled.td`
  height: 50px;
  display: table-cell !important;
  color: #FFF !important;
  padding-right: 30px;
  text-align: right !important;
  border-right: none !important;
  &.aqua-header {
    color: #2BF5BA !important;
    text-transform: uppercase;
    font-weight: 700;
  }
  &.prices {
    p {
      font-size: 15px;
      :last-of-type{
        font-size: 10px;
      }
    }
  }
  @media(max-width: 1000px) {
    text-align: left !important;
  }
`;

const ChangeSlidesButton = styled.button`
  background-color: #2BF5BA;
  color: #07064E;
  padding: 15px 20px;
  position: absolute;
  left: -9px;
  top: -90px;
  font-weight: 800;
  border-radius: 50%;
  border: none;
  outline: none;
  &.right-button {
    right: -9px;
    left: auto;
  }
  @media(min-width: 1000px) {
    display: none;
  }
`;
const CalculatorButton = styled(CustomButton)`
  position: absolute;
  bottom: -55px;
  z-index: 99999999999999;
  white-space: nowrap;
`;
const MainWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;
const RightArrow = styled.i`
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
`;
const LeftArrow = styled(RightArrow)`
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
`;

const PriceTable:FunctionComponent<TestProps> = ({ activeSection}) => {
    const [priceIndex, setPriceIndex] = useState(2);

    const handleIncreasePriceIndex = () => {
      if (priceIndex < 7) {
        setPriceIndex(priceIndex +1);
      } else {
        setPriceIndex(2);
      }
    }
  const handleDecreasePriceIndex = () => {
    if (priceIndex !== 2) {
      setPriceIndex(priceIndex -1);
    } else {
      setPriceIndex(7);
    }
  }
    const servicePrices = [
    {
      title: "Wydarzenie z udziałami",
      description: "koszt jednego uruchomienia",
      prices: ['200', '200', '150', '150', '100', '100'],
    },
    {
      title: "eksport raportu do PDF",
      description: "uruchomienie usług w 1 wydarzeniu",
      prices: ['30', '0', '0', '0', '0', '0'],
    },
    {
      title: "videokonferencja",
      description: "1 min video za 1 osobe",
      prices: ['0,17', '0,17', '0,17', '0,17', '0,17', '0,17'],
    },
    {
      title: "autoryzacja SMS",
      description: "1 SMS za 1 osobe",
      prices: ['0,50', '0,30', '0,30', '0,30', '0,30', '0,30'],
    },
    {
      title: "czat",
      description: "koszt na 1 osobe",
      prices: ['1,00', '0,50', '0,50', '0,50', '0,50', '0,50'],
    },
  ]

  return (
    <MainWrapper>
    <TableWrapper>
      <table cellSpacing={0} cellPadding={0}>
        <tbody>
      <TableWhiteRow priceIndex={priceIndex}>
        <SideCell className="aqua-header">{activeSection == 'monthPlan' ? 'KOSZT MIESIECZNY' : 'KOSZT ROCZNY'}</SideCell>
        <th>0<span>zł</span></th>
        <th>30{activeSection != 'monthPlan' ? '0' : ''}<span> zł</span></th>
        <th>50{activeSection != 'monthPlan' ? '0' : ''}<span> zł</span></th>
        <th>90{activeSection != 'monthPlan' ? '0' : ''}<span> zł</span></th>
        <th>120{activeSection != 'monthPlan' ? '0' : ''}<span> zł</span></th>
        <th>250{activeSection != 'monthPlan' ? '0' : ''}<span> zł</span></th>
      </TableWhiteRow>
      <TableWhiteRow priceIndex={priceIndex}>
        <SideCell>Liczba głosów w miesiącu</SideCell>
        <td>0</td>
        <td>100</td>
        <td>250</td>
        <td>500</td>
        <td>500</td>
        <td>500</td>
      </TableWhiteRow>
      <TableWhiteRow className="grey" priceIndex={priceIndex}>
        <SideCell><p>koszt dodatkowego</p><p>jednego głosu</p></SideCell>
        <td>0,50 zł</td>
        <td>0,50 zł</td>
        <td>0,50 zł</td>
        <td>0,50 zł</td>
        <td>0,50 zł</td>
        <td>0,50 zł</td>
      </TableWhiteRow>
      <TableWhiteRow priceIndex={priceIndex}>
        <SideCell className="aqua-header">DODATKOWE KOSZTY:</SideCell>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </TableWhiteRow>
      {servicePrices.map((service,index) => (
        <TableWhiteRow key={index} priceIndex={priceIndex}>
          <SideCell className="prices"><p>{service.title}</p><p>{service.description}</p></SideCell>
          {service.prices.map((price, priceIndex) => (
            <td key={priceIndex}>{price} zł</td>
          ))}
        </TableWhiteRow>
      ))}
        </tbody>
      </table>
      </TableWrapper>
        <ChangeSlidesButton onClick={()=> handleDecreasePriceIndex()}>
          <LeftArrow />
        </ChangeSlidesButton>
        <ChangeSlidesButton className="right-button" onClick={()=> handleIncreasePriceIndex()}>
          <RightArrow />
        </ChangeSlidesButton>
      <CalculatorButton
        text='skorzystaj z kalkulatora'
        onClick={()=> navigate('/calculator/')}>
      </CalculatorButton>
    </MainWrapper>
  );
};
export default PriceTable;
