import React from "react"
import styled from "styled-components"
import MockupSmall from "../images/mockup_small.png"
import MockupMiddle from "../images/mockup_wyborek.png"
import MockupBig from "../images/mockup_wyborek.png"
import SecondMockupSmall from "../images/second_mockup_wyborek_small.png"
import SecondMockupMiddle from "../images/second_mockup_wyborek.png"
import SecondMockupBig from "../images/second_mockup_wyborek.png"
import CrossPaginatorBlue from "../images/cross.svg"
import CrossPaginator from "../images/cross_white.svg"
import { CarouselProvider, Dot, Slide, Slider } from "pure-react-carousel"
import { Translate } from 'gatsby-plugin-translate'

const StyledMainWrapper = styled.div`
  display: flex;
  @media(min-width: 1024px) {
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
  }
`

const StyledMockupWrapper = styled.div`
  background-image: url(${MockupSmall});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right -100px center;
  height: 250px; 
  margin: 65px 0 20px;
  position: relative;
  @media(min-width: 320px) {  
    margin: 31px 0 55px;
    background-image: url(${MockupMiddle});
    background-position: right -41px top;
    height: 340px;
  }  
  @media(min-width: 768px) {  
    background-size: contain;
    margin: 0;
    height: calc( 54vh - 12px );
    background-position: right -79px top -16px;
  }
  @media(min-width: 1024px) {   
    width: 146%;
    background-image: url(${MockupBig});
    height: calc( 100vh - 288px );
    background-position: right 94% center;
    margin-right: -24px;
    background-size: 110%;
  }
  @media(min-width: 1366px) {   
    //background-position: right -321% center;
    //background-size: 97%;
    background-position: right 110% center;
    background-size: 122%;
  }
  @media(min-width: 1564px) {
    background-position: right 110% center;
    background-size: 110%;
  }  
  @media(min-width: 1706px) {
    //background-position: right -136% center;
    background-position: right 100% center;
    background-size: 92%;
}
  } 
  @media( min-width: 2000px) {
    //width: 68%;
    background-size: 1189px;

  }
  @media(min-width: 2500px) {
    //width: 48%;
  } 
`
const StyledSecondMockupWrapper = styled.div`
  background-image: url(${SecondMockupSmall});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right -100px center;
  height: 250px; 
  margin: 65px 0 20px;
  position: relative;
  @media(min-width: 320px) {  
    margin: 31px 0 55px;
    background-image: url(${SecondMockupMiddle});
    background-position: right -41px top;
    height: 340px;
  }  
  @media(min-width: 768px) {  
    background-size: contain;
    margin: 0;
    height: calc( 54vh - 12px );
    background-position: right -79px top -16px;
  }
  @media(min-width: 1024px) {   
    width: 146%;
    background-image: url(${SecondMockupBig});
    height: calc( 100vh - 288px );
    background-position: right 94% center;
    margin-right: -24px;
    background-size: 110%;
  }
  @media(min-width: 1366px) {   
    //background-position: right -321% center;
    //background-size: 97%;
    background-position: right 110% center;
    background-size: 122%;
  }
  @media(min-width: 1564px) {
    background-position: right 110% center;
    background-size: 110%;
  }  
  @media(min-width: 1706px) {
    //background-position: right -136% center;
    background-position: right 100% center;
    background-size: 92%;
}
  } 
  @media( min-width: 2000px) {
    //width: 68%;
    background-size: 1189px;

  }
  @media(min-width: 2500px) {
    //width: 48%;
  } 
`
const StyledTextWrapper = styled.div`
  padding: 0 35px;
  
  @media(min-width: 320px) {
      h1, p {
    padding-right: 0;
    padding-left: 28px;
    }
    padding: 0;
    margin-top: 0;
  }
  @media(min-width: 375px) {
    h1, p {
    padding-right: 47px;
    padding-left: 28px;
    }
  } 
  @media(min-width: 556px) {
    h1, p {
      padding-right: 170px;
    }
  }
  @media(min-width: 768px) {
    padding-left: 25px
  }
  @media(min-width: 320px) {
    padding-top: 131px;
  }
  @media(min-width: 1024px) {
    h1 {
      padding-right: 50px;  
    }
    display: flex;
    align-items: center;
    padding-top: 102px;
  }
  @media(min-width: 1366px) {
    padding-left: 78px;
    width: 100%;
    overflow: hidden;
    h1 {
      padding-right: 19px;
    }
  }
  @media(min-width: 1660px) {
    h1 {
      //width: 77%;
    }
   }
   @media(min-width: 1804px) {
    h1 {
      //padding-right: 110px;
      width: 1900px;
      max-width: 1900px;
    }
   }
    @media(min-width: 2000px) {
    h1 {
      width: 1644px;
    } 
    @media(min-width: 2200px) {
      padding-left: 200px;
  } 
  @media(min-width: 2350px) {
      padding-left: 290px;
  }
`
const StyledHeader = styled.h1`
  font-size: 30px;
  letter-spacing: -1px;
  line-height: 39px;
  font-weight: 800;
  color: #07064E;  
  span {
    color: #2AF5BA;
  }
  @media(min-width: 768px) {
    font-size: 50px;
    line-height: 63px;
  }
  @media(min-width: 1024px) {
    font-size: 42px;
    line-height: 49px;
  }
  @media(min-width: 1366px) {
    font-size: 64px;
    line-height: 71px;
  }
`

const StyledInput = styled.div`
  position: absolute;
  top: 85%;
  left: 30px;
 
  @media (min-width: 768px) {
    top: 81%;
    left: 50px;
  }
  @media (min-width: 1024px) {
    left: 50px;
    top: 50%;
  }
  @media (min-width: 1366px) {
    left: 105px;
    top: 65%;
  } 
  @media (min-width: 1842px) {
    top: 63%;
  }
  @media (min-width: 2200px) {
    left: 200px;
  } 
  @media (min-width: 2350px) {
    left: 290px;
  }
  button {
    color: #0C0B52;
    background: none;
    width: auto;
    height: 40px;
    border: none;
    font-weight: bold;
    font-size: 12px;
  }
`

const StyledButton = styled.div`
  background-color: #2BF5BA;
  border-radius: 20px;
  width: 170px;
  position: absolute;
  top: 85%;
  left: 0;
  text-align: center;
`;

const StyledSlider = styled.div`
  overflow: hidden;
  ul {
    list-style: none;
    display: flex;
    transform: none;
    padding: 0;
      li {
        width: 100%;
        padding-bottom: 0 !important;
      }
  }
`

const StyledDot = styled.div`
  position: absolute;
  left: 50%;
  button {
      background-image: url(${CrossPaginator});
      background-size: 19px !important;
      background-position: center;
      background-repeat: no-repeat;
      width: 19px;
      height: 19px ;
      padding: 0;
      border: none;
      background-color: transparent!important;
      margin: 5px;
        &:disabled{
          background-image: url(${CrossPaginatorBlue});
        }
    }
   @media (min-width: 433px) {
      top: 66%;
   }
   @media (min-width: 433px) {
      top: 65%;
   }
   @media (min-width: 477px) {
      top: 64%;
   }
   @media (min-width: 511px) {
      top: 63%;
   }
   @media (min-width: 571px) {
     top: 62%;
   }
   @media (min-width: 701px) {
      top: 61%;
   }
   @media (min-width: 701px) {
      top: 60%;
   }
   @media (min-width: 768px) {
      top: 59%;
   }
   @media (min-width: 823px) {
      top: 58%;
   }
   @media (min-width: 1024px) {
      top: 62%;
      left: 50%;
   }
   @media (min-width: 1116px) {
      top: 62%;
   }
   @media (min-width: 1366px) {
      top: 79%;
   }
`

const SliderComponent = () => {
  return (
    <StyledSlider id={"slider"}>
      <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={125}
        infinite={true}
        interval={3000}
        totalSlides={2}
        visibleSlides={1}
        touchEnabled={true}
        isPlaying={true}
        playDirection={'forward'}
      >
        <Slider>
          <Slide index={0}>
            <StyledMainWrapper>
              <StyledTextWrapper>
                <StyledHeader>
                  <span><Translate id="first.slide.title.security"/></span><Translate id="first.slide.description"/>
                </StyledHeader>
                <StyledMockupWrapper/>
              </StyledTextWrapper>
            </StyledMainWrapper>
          </Slide>
          <Slide index={1}>
            <StyledMainWrapper>
              <StyledTextWrapper>
                <StyledHeader>
                  <span>Wideokonferencja</span><br/> i czat dla uczestników wydarzenia
                </StyledHeader>
                <StyledSecondMockupWrapper/>
              </StyledTextWrapper>
            </StyledMainWrapper>
          </Slide>
          {/*<Slide index={2}>*/}
          {/*  <StyledMainWrapper>*/}
          {/*    <StyledTextWrapper>*/}
          {/*      <StyledHeader>*/}
          {/*        <span>System</span> do głosowań online. Pięknie zaprojektowany i bezpieczny.*/}
          {/*      </StyledHeader>*/}
          {/*      <StyledMockupWrapper/>*/}
          {/*    </StyledTextWrapper>*/}
          {/*  </StyledMainWrapper>*/}
          {/*</Slide>*/}
        </Slider>
        <StyledDot>
          <Dot slide={0}/>
          <Dot slide={1}/>
        {/*  <Dot slide={2}/>*/}
        </StyledDot>
      </CarouselProvider>
      <StyledInput>
        <StyledButton>
          <a href="https://admin.wyborek.pl/register"><button><Translate id="menu.sign_up" /></button></a>
        </StyledButton>
      </StyledInput>
    </StyledSlider>
  )
}

export default SliderComponent
