import React from "react"
import styled from "styled-components"
import { CarouselProvider, Slide, Slider } from "pure-react-carousel"
import SliderFirstImg from "../images/for_whom_undertaking.jpg"
import UnderTaking from "../images/for_whom_undertaking_bg.png"
import SliderSecImg from "../images/for_whom_cooperative_banks.jpg"
import BanksBg from "../images/for_whom_banks_bg.png"
import SliderThirdImg from "../images/for_whom_state_institutions.jpg"
import StateInstitutionBg from "../images/for_whom_state_bg.png"
import SliderFourthImg from "../images/for_whom_meeting.jpg"
import SliderFifthImg from "../images/for_whom_general_meetings.jpg"
import SliderSixthImg from "../images/for_whom_housing_communities.jpg"
import HouseCommunities from "../images/for_whom_housing_bg.png"
import Logo from "../images/logo.png"
import MiddleBg from "../images/for_whom_bg_middle.png"
import RightCircleBg from "../images/for_whom_bg_2.png"
import GreyElipseImg from "../images/grey_elipse.png"
import { Translate } from "gatsby-plugin-translate"

const StyledRelativeWrapper = styled.div`
  position: relative; 
  @media(min-width: 1024px) {
    padding-top: 40px;
  }
`

const StyledImgBg = styled.div`
  @media(min-width: 1024px) {
    background-image: url(${MiddleBg});
    background-repeat: no-repeat;
    background-size: calc( 96vh - 231px );
    background-position: left -376px bottom -20px;
    position: absolute;
    top: 250px;
    left: 0;
    width: 59%;
    height: 65%;
  }
  @media(min-width: 1366px) {
    background-size: calc( 101vh - 231px );
    top: 354px;
  }
`

const RightGrayCircleBg = styled.div`
  @media(min-width: 1366px) {
    background-image: url(${RightCircleBg});
    background-repeat: no-repeat;
    background-size: calc( 87vh - 231px );
    background-position: right -383px bottom -20px;
    position: absolute;
    top: 515px;
    left: 0;
    width: 100%;
    height: 65%;
`

const GreyElipse = styled.div`
  @media(min-width: 1366px) {
    background-image: url(${GreyElipseImg});
    background-repeat: no-repeat;
    background-size: calc( 13vh - -41px );
    background-position: left 120px top 5px;
    position: absolute;
    top: 218px;
    left: -232px;
    width: 101%;
    height: 42%;
  }
   &:before {
        display: none;
      }
`

const StyledMainWrapper = styled.div`
  padding: 0 30px;
  @media(min-width: 768px) {
    background-image: url(${MiddleBg});
    background-repeat: no-repeat;
    background-size: 53%;
    background-position: left -61% bottom -20px;
}
  }
  @media(min-width: 768px) {
    padding-left: 60px;
    padding-right: 60px;
    //padding-bottom: 164px;
  }
  @media(min-width: 1024px) {
    max-width: 760px;
    margin: 0 auto;
    padding: 0;
    background-image: none;
  }
`

const StyledTitle = styled.div`
  color: #07064E; 
  font-size: 26px;
  display: flex;
  justify-content: center;
  position: relative;
  padding-bottom: 46px;
  padding-top: 120px;
  text-align: center;
  h2 {
  margin-bottom: 21px;
  font-weight: 800;
  }
  
  &:before {
    content: "";
    height: 10px;
    width: 10px;
    background-color: #2BF5BA;
    border-radius: 50%;
    position: absolute;
    left: 48%;
    top: 39%;
    }
  @media (min-width: 1024px) {
    padding-top: 0;
    margin-top: 80px;
    &:before {
      top: -4%;
    }
  }
  @media(min-width: 1366px) {
    h2 {
      font-size: 44px;
    }
  }
`

const StyledTextDescription = styled.div`
 p {
  line-height: 31px;
  text-align: center;
  color: #07064E;
  @media(min-width: 768px) {
    max-width: 518px;
    margin: 0 auto;
      p {
        height: 103px;
      }
  }
  @media(min-width: 1024px) {
    max-width: max-content;
  }
 }
`

const StyledSlider = styled.div`
  overflow: hidden;
  ul {
    list-style: none;
    display: flex;
    justify-content: space-between;
    transform: none;
    padding: 0;
    touch-action: none !important;
      li {
        padding-bottom: 0 !important;
      }
    }
    @media(max-width: 768px) {
      ul {
        margin-left: -24%;
        li:nth-of-type(1), li:nth-of-type(7) {
          div {
            h3 {
             margin-bottom: 50px !important; 
            }
          }
        }
      }
    }
  div {
      padding: 20px 0;
  }
  @media(min-width: 768px) {
    display: none;
  }
`

const StyledSlideBox = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   color: #07064E;
   font-size: 20px;
   position: relative;
  h3 {
    margin-bottom: 27px;
    text-align: center;
      &:before {
        content: "";
        height: 5px;
        width: 5px;
        background-color: #07064E;
        border-radius: 50%;
        position: absolute;
        left: 47%;
        top: -20px;
      }
    }
    @media(max-width: 768px) {
      margin: 0 30px;
      h3 {
        font-size: 20px;
      }
      span {
        display: block;
      }
    }
    @media(min-width: 768px) {
      h3 {
        width: 50%;
      }
    }
`

const StyledSliderFirstImg = styled.div`
  background-image: url(${SliderFirstImg});
  height: 130px;
  width: 130px;
  background-repeat: no-repeat;
  background-size: cover;
  transition: 0.3s;
  border-radius: 50%;
  position: relative;
    div {
      margin-top: 28px;
    }
    @media(min-width: 768px) {
      height: 145px;
      width: 145px;
      margin: 0;
       &:before {
        height: 130px;
        width: 130px;
      }
    }
`

const StyledFirstTextBox = styled.div`
  position: absolute;
  top: 10%;
  left: 52%;
  width: min-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  transform: translate(-50%, 0);
  img {
    width: 200px;
    height: 200px;
  }
    @media(min-width: 768px) {
    span {
      display: block;
    }
  }
`

const StyledFirstBoxBg = styled.div`
  transition: 0.3s;
`

const StyledFirstImgBorderBox = styled.div`
  position: absolute;
  top: 127px;
  left: 0;
  transform: translate(-50%,-50%);
  height: 130px;
  width: 130px;
  border: 2px solid rgba(255, 255, 255, .7);
  border-radius: 50%;
`

const StyledFirstImgBgBox = styled.div`
  position: absolute;
  z-index: -2;
  top: 34px;
  left: -85px;
  height: 200px;
  width: 208px;
  background-size: 86%;
  background-image: url(${UnderTaking});
  background-repeat: no-repeat;
`

const StyledSliderSecImg = styled.div`
  background-image: url(${SliderSecImg});
  height: 130px;
  width: 130px;
  background-repeat: no-repeat;
  background-size: cover;
  transition: 0.3s;
  margin-right: -15px;
  position: relative;
  border-radius: 50%;
    @media(min-width: 768px) {
      height: 145px;
      width: 145px;
      &:before {
        height: 130px;
        width: 130px;
      } 
    &:after {
        top: 11px;
        left: -26px;
        background-size: 98%;
      }
    }
`

const StyledSecTextBox = styled.div`
  position: absolute;
  top: 23%;
  left: 0;
  width: min-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  @media(min-width: 768px) {
    span {
      display: block;
    }
  }
`

const StyledSecBoxBg = styled.div`
  transition: 0.3s;
`

const StyledSecImgBorderBox = styled.div`
  position: absolute;
  top: 153px;
  left: 72px;
  transform: translate(-50%,-50%);
  height: 130px;
  width: 130px;
  border: 2px solid rgba(163, 244, 255, .7);
  border-radius: 50%;
`

const StyledSecImgBgBox = styled.div`
  position: absolute;
  z-index: -2;
  top: 99px;
  left: -18px;
  height: 200px;
  width: 208px;
  background-size: 91%;
  background-image: url(${BanksBg});
  background-repeat: no-repeat;
  transform: rotate(-7deg);
`

const StyledSliderThirdImg = styled.div`
  background-image: url(${SliderThirdImg});
  height: 130px;
  width: 130px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  transition: 0.3s;
  border-radius: 50%;
  position: relative;
    @media(min-width: 768px) {
      height: 145px;
      width: 145px;
      &:before {
        height: 130px;
        width: 130px;
      }
      &:after {
        top: -25px;
        left: 45px;
        background-size: 63%;
      }
    }
`

const StyledThirdTextBox = styled.div`
  position: absolute;
  top: 23%;
  right: 0;
  width: min-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin-right: -15px;
    @media(min-width: 768px) {
    span {
      display: block;
    }
  }
`

const StyledThirdBoxBg = styled.div`
  transition: 0.3s;
`

const StyledThirdImgBorderBox = styled.div`
  position: absolute;
  top: 152px;
  left: -57px;
  transform: translate(-50%,-50%);
  height: 130px;
  width: 130px;
  border: 2px solid rgba(82, 255, 204, .7);
  border-radius: 50%;
`

const StyledThirdImgBgBox = styled.div`
  position: absolute;
  z-index: -2;
  top: 64px;
  left: -71px;
  height: 200px;
  width: 208px;
  background-size: 55%;
  background-image: url(${StateInstitutionBg});
  background-repeat: no-repeat;
`

const StyledSliderFourthImg = styled.div`
  background-image: url(${SliderFourthImg});
  height: 130px;
  width: 130px;
  background-repeat: no-repeat;
  background-size: cover;
  transition: 0.3s;
  margin-right: -15px;
  position: relative;
  border-radius: 50%;
    @media(min-width: 768px) {
      height: 145px;
      width: 145px;
      &:before {
        height: 130px;
        width: 130px;
      }
      &:after {
        top: 11px;
        left: -26px;
        background-size: 98%;
      }
    }
`

const StyledFourthTextBox = styled.div`
  position: absolute;
  top: 59%;
  left: 0;
  width: min-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
    @media(min-width: 768px) {
    span {
      display: block;
    }
  }
`

const StyledFourthBoxBg = styled.div`
  transition: 0.3s;
`

const StyledFourthImgBorderBox = styled.div`
  position: absolute;
  top: 153px;
  left: 72px;
  transform: translate(-50%,-50%);
  height: 130px;
  width: 130px;
  border: 2px solid rgba(163, 244, 255, .7);
  border-radius: 50%;
`

const StyledFourthImgBgBox = styled.div`
  position: absolute;
  z-index: -2;
  top: 99px;
  left: -18px;
  height: 200px;
  width: 208px;
  background-size: 91%;
  background-image: url(${BanksBg});
  background-repeat: no-repeat;
  transform: rotate(-7deg);
`

const StyledSliderFifthImg = styled.div`
  background-image: url(${SliderFifthImg});
  height: 130px;
  width: 130px;
  background-repeat: no-repeat;
  background-size: cover;
  transition: 0.3s;
  border-radius: 50%;
  position: relative;
    @media(min-width: 768px) {
      height: 145px;
      width: 145px;
      &:before {
        height: 130px;
        width: 130px;
      }
      &:after {
       top: 9px;
       left: -39px;
       background-size: 94%;
       transform: rotate(-5deg);
      }
    }
`

const StyledFifthTextBox = styled.div`
  position: absolute;
  top: 59%;
  right: 0;
  width: min-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin-right: -15px;
    @media(min-width: 768px) {
    span {
      display: block;
    }
  }

`

const StyledFifthBoxBg = styled.div`
  transition: 0.3s;
`

const StyledFifthImgBorderBox = styled.div`
    position: absolute;
    top: 152px;
    left: -57px;
    transform: translate(-50%,-50%);
    height: 130px;
    width: 130px;
    border: 2px solid rgba(82, 255, 204, .7);
    border-radius: 50%;
`

const StyledFifthImgBgBox = styled.div`
  position: absolute;
  z-index: -2;
  top: 91px;
  left: -160px;
  height: 200px;
  width: 208px;
  background-size: 94%;
  background-image: url(${HouseCommunities});
  background-repeat: no-repeat;
  transform: rotate(-20deg);
`

const StyledSliderSixthImg = styled.div`
  background-image: url(${SliderSixthImg});
  height: 130px;
  width: 130px;
  background-repeat: no-repeat;
  margin-right: 10px;
  background-size: cover;
  border-radius: 50%;
  position: relative;
    @media(min-width: 768px) {
      height: 145px;
      width: 145px;
      &:before {
        height: 130px;
        width: 130px;
      }
     &:after {
       top: 9px;
       left: -39px;
       background-size: 94%;
       transform: rotate(-5deg);
      }
    }
`

const StyledSixTextBox = styled.div`
  position: absolute;
  bottom: 0;
  left: 52%;
  width: min-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  transform: translate(-50%, 0);
    @media(min-width: 768px) {
    span {
      display: block;
    } 
    }
`

const StyledSixBoxBg = styled.div`
   transition: 0.3s;
`

const StyledSixImgBorderBox = styled.div`
  position: absolute;
  top: -72px;
  left: -6px;
  transform: translate(-50%,-50%);
  height: 130px;
  width: 130px;
  border: 2px solid rgba(82, 255, 204, .7);
  border-radius: 50%;
`

const StyledSixImgBgBox = styled.div`
  position: absolute;
  z-index: -2;
  top: -136px;
  left: -107px;
  height: 200px;
  width: 208px;
  background-size: 94%;
  background-image: url(${HouseCommunities});
  background-repeat: no-repeat;
  transform: rotate(-20deg);
`

const StyledBoxWrapper = styled.div`
  @media(max-width: 767px) {
    display: none;
  }
  position: relative;
  height: 870px;
    div {
      h3 {
        &:before {
          content: "";
          height: 5px;
          width: 5px;
          background-color: #07064E;
          border-radius: 50%;
          position: absolute;
          left: 47%;
          top: -20px;
      }
      }
    }
  h3 {
    padding-top: 10px;
    padding-bottom: 20px;
    font-weight: normal;
    color: #07064E;
  }
`

const StyledFirstBox = styled.div`
   position: absolute;
    top: 10%;
    left: 51%;
    &:hover {
    transform: scale(1.05);
    h3 {
      font-weight: bold !important;
    }
   }
   transition: 0.3s;
`

const StyledSecBox = styled.div`
   position: absolute;
    top: 23%;
    left: 0;
    &:hover {
    transform: scale(1.05);
    h3 {
      font-weight: bold !important;
    }
   }
   transition: 0.3s;
`

const StyledThirdBox = styled.div`
   position: absolute;
    top: 23%;
    right: 0;
    &:hover {
    transform: scale(1.05);
    h3 {
      font-weight: bold !important;
    }
   }
   transition: 0.3s;
`

const StyledFourthBox = styled.div`
   position: absolute;
    top: 59%;
    left: 0;
    &:hover {
    transform: scale(1.05);
    h3 {
      font-weight: bold !important;
    }
   }
   transition: 0.3s;
`

const StyledFifthBox = styled.div`
   position: absolute;
    top: 59%;
    right: 0;
    &:hover {
    transform: scale(1.05);
    h3 {
      font-weight: bold !important;
    }
   }
   transition: 0.3s;
`

const StyledSixthBox = styled.div`
    position: absolute;
    top: 97%;
    left: 51%;
    &:hover {
    transform: scale(1.05);
    h3 {
      font-weight: bold !important;
    }
   }
   transition: 0.3s;
`

const StyledLogo = styled.div`
  position: absolute;
  top: 43%;
  left: 50%;
  transform: translate(-45%,0%);
  background-image: url(${Logo});
  width: 240px;
  height: 156px;
  background-repeat: no-repeat;
    &:before {
     display: none;
    }
`

const StyledFirstSlide= styled.div`
  padding: 0 0 20px 0 !important;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 142px;
    left: 66px;
    transform: translate(-50%, -50%);
    border: 2px solid rgba(255,255,255,.7);
    height: 110px;
    width: 110px;
    border-radius: 50%;
    z-index: 2;
  }
  
  &:after {
    content: "";
    position: absolute;
    top: 51px;
    left: -11px;
    background-image: url(${UnderTaking});
    z-index: -2;
    height: 200px;
    width: 208px;
    background-size: 80%;
    background-repeat: no-repeat;
  }
`;

const StyledSecSlide= styled.div`
  padding: 0 0 20px 0 !important;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 146px;
    left: 65px;
    transform: translate(-50%, -50%);
    border: 2px solid rgba(163,244,255,.7);
    height: 110px;
    width: 110px;
    border-radius: 50%;
    z-index: 2;
  }
  
  &:after {
    content: "";
    position: absolute;
    top: 88px;
    left: -27px;
    background-image: url(${BanksBg});
    z-index: -2;
    height: 200px;
    width: 208px;
    background-size: 85%;
    background-repeat: no-repeat;
  }
`;

const StyledThirdSlide= styled.div`
  padding: 0 0 20px 0 !important;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 146px;
    left: 65px;
    transform: translate(-50%, -50%);
    border: 2px solid rgba(82, 255, 204, .7);
    height: 110px;
    width: 110px;
    border-radius: 50%;
    z-index: 2;
  }
  
  &:after {
    content: "";
    position: absolute;
    top: 61px;
    left: 47px;
    background-image: url(${StateInstitutionBg});
    z-index: -2;
    height: 200px;
    width: 208px;
    background-size: 53%;
    background-repeat: no-repeat;
  }
`;

const StyledFourthSlide= styled.div`
  padding: 0 0 20px 0 !important;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 146px;
    left: 65px;
    transform: translate(-50%, -50%);
    border: 2px solid rgba(163,244,255,.7);
    height: 110px;
    width: 110px;
    border-radius: 50%;
    z-index: 2;
  }
  
  &:after {
    content: "";
    position: absolute;
    top: 88px;
    left: -27px;
    background-image: url(${BanksBg});
    z-index: -2;
    height: 200px;
    width: 208px;
    background-size: 85%;
    background-repeat: no-repeat;
  }
`;

const StyledFifthSlide= styled.div`
  padding: 0 0 20px 0 !important;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 146px;
    left: 65px;
    transform: translate(-50%, -50%);
    border: 2px solid rgba(82, 255, 204, .7);
    height: 110px;
    width: 110px;
    border-radius: 50%;
    z-index: 2;
  }
  
  &:after {
    content: "";
    position: absolute;
    top: 93px;
    left: -40px;
    background-image: url(${HouseCommunities});
    z-index: -2;
    height: 200px;
    width: 208px;
    background-size: 84%;
    background-repeat: no-repeat;
  }
`;

const StyledSixthSlide = styled.div`
  padding: 0 0 20px 0 !important;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 146px;
    left: 65px;
    transform: translate(-50%, -50%);
    border: 2px solid rgba(82, 255, 204, .7);
    height: 110px;
    width: 110px;
    border-radius: 50%;
    z-index: 2;
  }
  
   &:after {
    content: "";
    position: absolute;
    top: 93px;
    left: -40px;
    background-image: url(${HouseCommunities});
    z-index: -2;
    height: 200px;
    width: 208px;
    background-size: 84%;
    background-repeat: no-repeat;
  }
`;

const ForWhom = () => (
  <>
    <StyledRelativeWrapper id={"forWhom"}>
      <StyledImgBg/>
      <RightGrayCircleBg/>
      <StyledMainWrapper>
        <StyledTitle>
          <h2><Translate id="for_whom"/></h2>
        </StyledTitle>
        <StyledTextDescription>
          <p>
            <Translate id="for_whom.description"/>
          </p>
        </StyledTextDescription>
        <StyledBoxWrapper>
          <GreyElipse/>
          <StyledLogo/>
          <StyledFirstBox>
            <StyledFirstTextBox>
              <h3><Translate id="enterprises"/></h3>
              <StyledSliderFirstImg/>
            </StyledFirstTextBox>
            <StyledFirstBoxBg>
              <StyledFirstImgBorderBox/>
              <StyledFirstImgBgBox/>
            </StyledFirstBoxBg>
          </StyledFirstBox>
          <StyledSecBox>
            <StyledSecTextBox>
              <h3><span><Translate id="banks"/></span> <Translate id="cooperative"/></h3>
              <StyledSliderSecImg/>
            </StyledSecTextBox>
            <StyledSecBoxBg>
              <StyledSecImgBorderBox/>
              <StyledSecImgBgBox/>
            </StyledSecBoxBg>
          </StyledSecBox>
          <StyledThirdBox>
            <StyledThirdTextBox>
              <h3><span><Translate id="institutions"/></span> <Translate id="state_institutions"/></h3>
              <StyledSliderThirdImg/>
            </StyledThirdTextBox>
            <StyledThirdBoxBg>
              <StyledThirdImgBorderBox/>
              <StyledThirdImgBgBox/>
            </StyledThirdBoxBg>
          </StyledThirdBox>
          <StyledFourthBox>
            <StyledFourthTextBox>
              <h3><span><Translate id="meetings"/></span> <Translate id="shareholders"/></h3>
              <StyledSliderFourthImg/>
            </StyledFourthTextBox>
            <StyledFourthBoxBg>
              <StyledFourthImgBorderBox/>
              <StyledFourthImgBgBox/>
            </StyledFourthBoxBg>
          </StyledFourthBox>
          <StyledFifthBox>
            <StyledFifthTextBox>
              <h3><span><Translate id="event_with_shares"/></span></h3>
              <StyledSliderFifthImg/>
            </StyledFifthTextBox>
            <StyledFifthBoxBg>
              <StyledFifthImgBorderBox/>
              <StyledFifthImgBgBox/>
            </StyledFifthBoxBg>
          </StyledFifthBox>
          <StyledSixthBox>
            <StyledSixTextBox>
              <h3><span><Translate id="communities"/></span> <Translate id="housing_communities"/></h3>
              <StyledSliderSixthImg/>
            </StyledSixTextBox>
            <StyledSixBoxBg>
              <StyledSixImgBorderBox/>
              <StyledSixImgBgBox/>
            </StyledSixBoxBg>
          </StyledSixthBox>
        </StyledBoxWrapper>
      </StyledMainWrapper>
      <StyledSlider>
        <CarouselProvider
          naturalSlideWidth={70}
          naturalSlideHeight={125}
          totalSlides={6}
          visibleSlides={2}
          // isPlaying={true}
          infinite={true}
          lockOnWindowScroll={true}
        >
          <Slider>
            <Slide index={0}>
              <StyledSlideBox>
                <StyledFirstSlide>
                  <h3><Translate id="enterprises"/></h3>
                  <StyledSliderFirstImg/>
                </StyledFirstSlide>
              </StyledSlideBox>
            </Slide>
            <Slide index={1}>
              <StyledSlideBox>
                <StyledSecSlide>
                  <h3><span><Translate id="banks"/></span> <Translate id="Spółdzielcze"/></h3>
                  <StyledSliderSecImg/>
                </StyledSecSlide>
              </StyledSlideBox>
            </Slide>
            <Slide index={2}>
              <StyledSlideBox>
                <StyledThirdSlide>
                  <h3><span><Translate id="institutions"/></span> <Translate id="state_institutions"/></h3>
                  <StyledSliderThirdImg/>
                </StyledThirdSlide>
              </StyledSlideBox>
            </Slide>
            <Slide index={3}>
              <StyledSlideBox>
                <StyledFourthSlide>
                  <h3><span><Translate id="meetings"/></span> <Translate id="shareholders"/></h3>
                  <StyledSliderFourthImg/>
                </StyledFourthSlide>
              </StyledSlideBox>
            </Slide>
            <Slide index={4}>
              <StyledSlideBox>
                <StyledFifthSlide>
                  <h3><span><Translate id="event_with_shares"/></span></h3>
                  <StyledSliderFifthImg/>
                </StyledFifthSlide>
              </StyledSlideBox>
            </Slide>
            <Slide index={5}>
              <StyledSlideBox>
                <StyledSixthSlide>
                  <h3><span><Translate id="communities"/></span> <Translate id="housing_communities"/></h3>
                  <StyledSliderSixthImg/>
                </StyledSixthSlide>
              </StyledSlideBox>
            </Slide>
            <Slide index={6}>
              <StyledSlideBox>
                <StyledFirstSlide>
                  <h3><Translate id="enterprises"/></h3>
                  <StyledSliderFirstImg/>
                </StyledFirstSlide>
              </StyledSlideBox>
            </Slide>
          </Slider>
        </CarouselProvider>
      </StyledSlider>
    </StyledRelativeWrapper>
  </>
)

export default ForWhom
