import React, { Component, useState } from "react"
import styled from "styled-components"
import PricingBgSmall from "../images/pricing_bg.svg"
import PlanIcon from "../images/pricing_plan_icon.svg"
import GreenLine from "./greenLine"
import { Translate } from "gatsby-plugin-translate"
import TemporaryPricingMonthlyImg from "../temp/pricing_monthly.png"
import TemporaryPricingYearlyImg from "../temp/pricing_yearly.png"
import PriceTable from "./price-table/priceTable"
import { Link, navigate } from "gatsby"
import CustomButton from "./common/customButton"

const StyledMainWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  @media (min-width: 320px) {
    padding: 0 20px;
  }
  @media (min-width: 768px) {
   // max-width: 800px;
  }
  @media (min-width: 1024px) {
    max-width: 1336px;
  }
  @media (min-width: 1366px) {
    padding-top: 70px;
  }
`

const StyledBg = styled.div`
  position: relative;
`

const PricingBg = styled.div`
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  height: 182%;
  width: 3500px;
  z-index: -1;
  @media (min-width: 320px) {
    background-image: url(${PricingBgSmall});
    background-size: 3940px;
    top: -530px;
    left: -1370px;
  }
  @media (min-width: 322px) {
    background-image: url(${PricingBgSmall});
    background-size: 3940px;
    top: -585px;
    left: -1370px;
  }
  @media (min-width: 375px) {
    background-image: url(${PricingBgSmall});
    background-size: 3800px;
    top: -620px;
    left: -1370px;
  }
  @media (min-width: 425px) {
    background-image: url(${PricingBgSmall});
    background-size: 3800px;
    top: -633px;
    left: -1370px;
  }
  @media (min-width: 545px) {
    background-size: 3340px;
    top: -430px;
    left: -1130px;
  }
  @media (min-width: 632px) {
    background-size: 3200px;
    top: -385px;
    left: -1130px;
  }
  @media (min-width: 768px) {
    background-size: 2990px;
    top: -460px;
    left: -962px;
  }
  @media (min-width: 1024px) {
    background-size: 3050px;
    top: -495px;
    left: -850px;
  }
  @media (min-width: 1366px) {
    background-size: 3475px;
    top: -554px;
    left: -1000px;
    height: 230%;
  }
  @media (min-width: 1660px) {
    top: -548px;
    left: -1100px;
  }
  @media (min-width: 1866px) {
    background-size: 2654px;
    top: -492px;
    left: -680px;
  }
  @media (min-width: 1909px) {
    left: -531px;
  }
`

const StyledTitle = styled.div`
  color: #FFFFFF;
  font-size: 26px;
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: 100px;
  padding-bottom: 41px;
  padding-top: 120px;

  h2 {
    font-weight: 800;
  }

  &:before {
    content: "";
    height: 10px;
    width: 10px;
    background-color: #2BF5BA;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
  }

  @media (min-width: 1366px) {
    padding-top: 0;
    &:before {
      top: -4%;
    }

    h2 {
      font-size: 44px;
    }
  }
`

const StyledPlanSwitch = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  margin-bottom: 50px;
  margin-left: -15px;

  button {
    background: none;
    border: none;
    padding: 8px 40px;
    border-radius: 20px;
    margin-right: -15px;
    outline: none;
    position: relative;
    cursor: pointer;
  }

  .activeButton {
    background-color: #2BF5BA;
    color: #07064E;
    font-weight: bold;
    z-index: 10;
  }

  .inactiveButton {
    background-color: #181572;
    color: #2BF5BA;
    font-weight: bold;
    z-index: 1;
  }
`

const StyledTemporaryPricingMonthlyImg = styled.div`
  display: block;
  height:470px;
  width:100vw;
  background-image: url(${TemporaryPricingMonthlyImg});
  background-size: 1060px;
  background-repeat: no-repeat;
  background-position: center;
  @media (max-width: 1366px) {
    background-position: left!important;
    background-size: 75%;
  }
  @media (max-width: 767px) {
    background-position: left!important;
    background-size: 100%;
  }
`
const StyledTemporaryPricingYearlyImg = styled.div`
  display: block;
  height:470px;
  width:100vw;
  background-image: url(${TemporaryPricingYearlyImg});
  background-size: 1060px;
  background-repeat: no-repeat;
  background-position: center;
  @media (max-width: 1366px) {
    background-position: left!important;
    background-size: 75%;
  }
  @media (max-width: 767px) {
    background-position: left!important;
    background-size: 100%;
  }
`

const StyledDescriptionHeader = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;

  h2 {
    color: #ffffff;
  }

  @media (min-width: 768px) {
    margin-bottom: 40px;
  }
  @media (min-width: 1366px) {
    margin-bottom: 40px;
    width: auto;
  }
`

const StyledPlan = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  @media (min-width: 1367px) {
    margin-bottom: 200px;
  }
`

const StyledPlanDescription = styled.div`
  border: 1px solid #0D2E60;
  font-size: 13px;
  font-weight: bold;
  height: auto;
  width: 100%;
  border-radius: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column !important;
  padding: 19px 0;
  margin-top: 40px;
  @media (min-width: 768px) {
    width: 850px;
    height: 90px;
    flex-direction: row !important;
    padding: 62px 0;
    margin-top: 0;
  }
`

const StyledPlanBox = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 0;
  width: 70%;
  position: relative;
  @media (min-width: 768px) {
    &:nth-of-type(1), &:nth-of-type(2), &:nth-of-type(3) {
      width: 33%;
    }
  }
  @media (min-width: 1366px) {
    padding: 15px
  }
`
const CalculatorButton = styled(CustomButton)`
  position: absolute;
  right: 63px;
  bottom: -40px;
`;


const StyledPlanIcon = styled.div`
  background-image: url(${PlanIcon});
  background-repeat: no-repeat;
  height: 17px;
  width: 18px;
  padding-right: 40px;
`

const StyledPlanText = styled.div`
  color: #ffffff;

  span {
    display: block;
  }
`

const StyledGreenLine = styled.div`
  position: relative;

  div {
    top: -133px;
    left: -555px;
  }

  @media (min-width: 1820px) {
    div {
      left: -471px;
    }
  }
`
const PricingWrapper = styled.div`
  margin-bottom: 85px;
  @media(max-width: 1000px) {
    margin-bottom: 130px;
  }
`;

const StyledBigPricePlan = styled.div`
  display: none;
  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
  }
  @media (min-width: 1366px) {
    flex-direction: row;
  }
`

const StyledMobilePricePlan = styled.div`
  @media (max-width: 767px) {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    border-radius: 20px;
    padding: 20px 0;
    &:hover {
      background-color: #1C1B64;
    }
  }
  display: none;
`


class MonthPlan extends Component {
  get show() {
    return this.props.activeSection === "monthPlan"
  }

  render() {
    const plans = [
      {
        name: <Translate id="price_plan.professional"/>,
        price: 0,
        description: <><Translate id="price_plan.ten_questions"/><Translate id="price_plan.ten_questions"/></>
      },
    ]
    if (this.show) {
      return (
        <>
          <StyledBigPricePlan>
            <StyledTemporaryPricingMonthlyImg/>
          </StyledBigPricePlan>
          {plans.map((plan, index) => (
            <StyledMobilePricePlan key={index}>
              <StyledTemporaryPricingMonthlyImg/>
            </StyledMobilePricePlan>
          ))}
        </>
      )
    } else {
      return null
    }
  }
}

class YearPlan extends Component {
  get show() {
    return this.props.activeSection === "yearPlan"
  }

  render() {
    const plans = [
      { name: <Translate id="price_plan.professional"/>, price: 132, description: <><Translate id="price_plan.thousand_responses"/><Translate id="price_plan.thousand_responses"/></> },
      { name: <Translate id="price_plan.premium"/>, price: 111, description: <Translate id="price_plan.thousand_responses"/> },
      {
        name: <Translate id="price_plan.basic"/>,
        price: 234,
        description: <><Translate id="price_plan.thousand_responses"/><Translate id="price_plan.thousand_responses"/></>
      },
      { name: <Translate id="price_plan.individual"/>, price: 333, description:<Translate id="price_plan.thousand_responses"/> },
      { name: <Translate id="price_plan.premium"/>, price: 44, description: <Translate id="price_plan.thousand_responses"/> }
    ]
    if (this.show) {
      return (
        <StyledTemporaryPricingYearlyImg/>
      )
    } else {
      return null
    }
  }
}


const Buttons = ({ onToggle, activeButton }) => (
  <div>
    <button className={activeButton === "monthPlan" ? "activeButton" : "inactiveButton"} name="monthPlan"
            onClick={onToggle}><Translate id="price_plan.monthly"/>
    </button>
    <button className={activeButton === "yearPlan" ? "activeButton" : "inactiveButton"} name="yearPlan"
            onClick={onToggle}><Translate id="price_plan.annually"/>
    </button>
  </div>
)

const Main = ({ activeSection }) => (
  <React.Fragment>
    <MonthPlan activeSection={activeSection}/>
    <YearPlan activeSection={activeSection}/>
  </React.Fragment>
)

class Pricing extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeSection: "monthPlan"
    }
    this.handleToggleSection = this.handleToggleSection.bind(this)
  }

  handleToggleSection(e) {
    const { name } = e.target
    this.setState(() => ({
      activeSection: name
    }))
  }

  render() {

    const planText = [
        "Dostęp do wszystkich usług w ramach każdego osobnego wydarzenia: czat, wideokonferencja, raport.",
        "Elastyczny sposób rozliczenia każdego wydarzenia w zależności od wykorzystania aplikacji: liczba oddanych głosów etc.",
        "Możliwość przejścia na inny plan abonamentowy oraz anulowania subskrypcji w dowolnym momencie"
      // <Translate id="price_plan.include.description"/>
    ]

    return (
      <StyledMainWrapper id={"pricing"}>
        <StyledBg>
          <PricingBg/>
          <PricingWrapper>
            <StyledTitle>
              <h2><Translate id="price_plan"/></h2>
              {/*<CalculatorButton*/}
              {/*  text='skorzystaj z kalkulatora'*/}
              {/*  onClick={()=> navigate('/calculator/')}>*/}
              {/*</CalculatorButton>*/}
            </StyledTitle>
            <StyledPlanSwitch>
              <Buttons onToggle={this.handleToggleSection} activeButton={this.state.activeSection}/>
            </StyledPlanSwitch>
            <PriceTable
              activeSection={this.state.activeSection}
            />
          </PricingWrapper>
          <StyledGreenLine>
            <GreenLine/>
          </StyledGreenLine>
          <div>
            <StyledDescriptionHeader>
              <h2>
                <Translate id="price_plan.include"/>
              </h2>
            </StyledDescriptionHeader>
            <StyledPlan>
              <StyledPlanDescription>
                {planText.map((text, index) => (
                  <StyledPlanBox key={index}>
                    <StyledPlanIcon/>
                    <StyledPlanText>
                      <p>{text}</p>
                    </StyledPlanText>
                  </StyledPlanBox>
                ))}
              </StyledPlanDescription>
            </StyledPlan>
          </div>
        </StyledBg>
      </StyledMainWrapper>
    )
  }
}


export default Pricing
