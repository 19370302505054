import React, { Component } from "react"
import styled from "styled-components"
import GreenLineBg from "../images/green_line.svg"

const GreenLineWrapper = styled.div`
  background-image: url(${GreenLineBg});
  width: 2000px;
  height: 100vh;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  background-size: contain;
`;

class GreenLine extends Component {
  render() {
    return (
      <GreenLineWrapper/>
    )
  }
}

export default GreenLine
