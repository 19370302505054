import React from "react"
import styled from "styled-components"
import SecurityImage from "../images/security.png"
import SecurityCircleBg from "../images/security_circle.png"
import { Translate } from "gatsby-plugin-translate"

const StyledSecurityWrapper = styled.div`
  position: relative;
  padding: 0 30px;
  margin: 0 auto 45px auto;
  @media (min-width: 768px) {
    max-width: calc(100vw - 57px);
    padding: 0;
    margin: 0 auto;
  }
  @media (min-width: 1024px) {
    max-width: calc(100vw - 129px);
  }
  @media (min-width: 1258px) {
    max-width: 1088px;
  }
`

const StyledTitle = styled.div`
  color: #07064E;
  font-size: 26px;
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: 70px;
  padding-bottom: 41px;
  padding-top: 120px;
  h2 {
    font-weight: 800;
  }

  &:before {
    content: "";
    height: 10px;
    width: 10px;
    background-color: #2BF5BA;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 51%;
  }
  @media(min-width: 768px) {
    margin-bottom: 36px;
  }
  @media (min-width: 1366px) {
    h2 {
      font-size: 44px;
    }
  }
`

const StyledSecurityBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const StyledSecurityText = styled.div`
  width: 100%;
  margin-bottom: 115px;
  position: relative;

  div {
    margin-bottom: 20px;

    p {
      line-height: 31px;
      font-size: 16px;
      color: #07064E;

      span {
        font-weight: bold;
      }
    }
  }

  @media (min-width: 769px) {
    width: 48%;
  }
`

const StyledCircleImg = styled.div`
  background-image: url(${SecurityCircleBg});
  background-repeat: no-repeat;
  position: absolute;
  bottom: -123px;
  left: -26px;
  height: 100px;
  width: 100px;
`

const StyledSecurityImg = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;

  @media (min-width: 769px) {
    width: 48%;

  }
`

const SecurityImg = styled.div`
  background-image: url(${SecurityImage});
  background-repeat: no-repeat;
  background-size: contain;
  height: calc(50vh - 41px);
  width: 460px;

  @media (max-width: 450px) {
    height: calc(50vh - 119px);
  }
  @media (min-width: 769px) {
    margin-top: -90px;
  }
`

const Security = () => (
  <StyledSecurityWrapper id={"security"}>
    <StyledTitle>
      <h2><Translate id="menu.security"/></h2>
    </StyledTitle>
    <StyledSecurityBox>
      <StyledSecurityText>
        <div>
          <p>
            Backend i bazy danych hostowane są na Digital Ocean.
            Produkcyjna baza danych nie jest widoczna z zewnątrz,
            łączyć z nią mogą się tylko usługi będące w tym samym VPC Digital Ocean.
          </p>
        </div>
        <div>
          <p>
            Baza danych jest backupowana i mamy możliwość przywrócenia jej do dowolnej sekundy z okresu 7 dni wstecz.
            Dodatkowo tworzone są backupy danych przyrostowe .
          </p>
        </div>
        <div>
          <p>
            Administratorzy wydarzenia nie mają dostępu do głosów udzielonych przez poszczególnych uczestników.
            Dane te nie są pobierane do części frontendowych / raportów PDF.
          </p>
        </div>
        <StyledCircleImg/>
      </StyledSecurityText>
      <StyledSecurityImg>
        <SecurityImg/>
      </StyledSecurityImg>
    </StyledSecurityBox>
  </StyledSecurityWrapper>
)

export default Security
